import React, { useMemo, useState } from "react";
import { Accordion, AccordionItem } from "@reach/accordion";
import "@reach/accordion/styles.css";
import { Box, Container, Typography } from "root/components/design-system";
import styled from "styled-components";
import { DropListType } from "root/hooks/useDroplistData";
import { down } from "styled-breakpoints";
import DropItem from "./DropItem";

const Des = styled(Typography)`
  color: white;
  text-align: center;
  font-size: 20px;
  font-family: ABCDiatype;
  a {
    color: white;
  }
  ${down("sm")} {
    font-size: 15px;
  }
`;

const FILTER_OPTIONS = [
  "View All",
  "Available",
  "Collectibles",
  "Phygital",
  "1 of 1 Artwork",
] as const;

export default function DropList({ drops }: { drops: DropListType[] }) {
  const [filter] = useState<typeof FILTER_OPTIONS[number]>("View All");

  const filteredDrops = useMemo(() => {
    if (filter === "Available") {
      return drops.filter((drop) => drop.quantity.available > 0);
    }

    if (filter === "Collectibles") {
      return drops.filter((drop) =>
        drop.assetTypes.find((item) => item.includes("_COLLECTIBLE"))
      );
    }

    if (filter === "Phygital") {
      return drops.filter((drop) =>
        drop.assetTypes.includes("PHYGITAL_COLLECTIBLE")
      );
    }

    if (filter === "1 of 1 Artwork") {
      return drops.filter((drop) => drop.assetTypes.includes("ARTWORK"));
    }

    return drops;
  }, [filter, drops]);

  return (
    <Container>
      <Box>
        <Des mt={{ _: 40, lg: 70 }}>
          Dissrup was an independent NFT art and collectibles project, created
          by{" "}
          <a href="https://yambo-studio.com/" target="_blank" rel="noreferrer">
            Yambo Studio
          </a>
          , that ran between October 2021 and November 2022. Working together
          with some of the industry’s most innovative creators, we developed a
          range of projects entitled ‘Dissrup Drops’, all of which have been
          archived here for posterity, and can be viewed below in their original
          form.
        </Des>
        <Des mt={{ _: 30, lg: 50 }} mb={{ _: 50, lg: 100 }}>
          <b>Important:</b> all items available for purchase on Dissrup are no
          longer accessible via the Dissrup platform, and any rewards or
          benefits previously outlined in their descriptions are no longer
          valid. Please refrain from attempting to purchase items listed as part
          of these drops, as this will likely lead to errors for which we cannot
          be held responsible. However, while Dissrup has ceased all operations,
          all Dissrup assets and their metadata have been safely archived so
          that owners and viewers will continue to have access to them in
          perpetuity.
        </Des>
      </Box>

      <Accordion collapsible>
        {filteredDrops?.map((drop) => (
          <AccordionItem key={drop.id}>
            <DropItem drop={drop} />
          </AccordionItem>
        ))}
      </Accordion>
    </Container>
  );
}
