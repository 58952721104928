import React, { useEffect, useMemo } from "react";
import { Box, Flex } from "root/components/design-system";
import DropList from "root/modules/DropsPage/DropList";
import { DropListType } from "root/hooks/useDroplistData";
import useHeaderLayoutStore from "root/hooks/useHeaderLayoutStore";
import InfiniteLooper from "root/components/InfiniteLooper";
import styled from "styled-components";
import MuxVideo from "root/components/MuxVideo";
import defaultRedaxios from "redaxios";
import { HomepageConfig } from "../../../types/cmsTypes";
import getSsrPropsWrapper from "@/utils/getSsrPropsWrapper";

const Wrapper = styled(Box)`
  [data-underliner] {
    background: linear-gradient(#fff 0 0) var(--d, 201%) 100% /200% 1px no-repeat;
  }
  [data-underliner]:hover {
    --d: 100%;
    transition: 0.5s;
  }
`;

const VideoWrapper = styled.div`
  width: 100vw;
  overflow: hidden;
  position: relative;
  height: calc(9 / 16 * 100vw);
`;

const VideoFrame = styled.iframe`
  width: 100%;
  height: calc(9 / 16 * 100vw);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface DropsPageProps extends HomepageConfig {
  chainId: "TESTNET" | "MAINNET";
  dropListData: DropListType[];
}

export default function DropsPage({ chainId, dropListData }: DropsPageProps) {
  const { setDarkMode } = useHeaderLayoutStore();

  const data = dropListData;

  useEffect(() => {
    setDarkMode(true);

    return () => {
      setDarkMode(false);
    };
  }, []);

  const dropList = useMemo(() => {
    if (!data?.length) {
      return [];
    }

    return data.filter((drop) =>
      chainId === "TESTNET"
        ? drop.slideConfig.isPublishedTestnet
        : drop.slideConfig.isPublished
    );
  }, [data]);

  return (
    <Wrapper>
      <VideoWrapper>
        <VideoFrame
          src="https://player.vimeo.com/video/818349446?autoplay=1&muted=1&background=1"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Vimeo video player"
        />
      </VideoWrapper>

      <Box position="relative" pb="100px" bg="#000" py={{ lg: "1rem" }} pt={20}>
        <InfiniteLooper speed={15}>
          <Flex
            alignItems="center"
            height={{ _: "40px", lg: "unset" }}
            mt={{ _: "2rem", md: "0" }}
            bg="#000"
          >
            <MuxVideo
              playbackId="0101m00BgSa9TbJdX3Wp02cGj2lFm02vbClK02hdFXexgSZis"
              muted
              loop
              playsInline
              style={{ height: "100%" }}
            />
            <Box
              as="svg"
              width={{ _: "20px", lg: "97" }}
              height={{ _: "20px", lg: "97" }}
              viewBox="0 0 97 97"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M87.8085 19.1653L70.5988 36.375L60.8206 41.8508L61.7984 44.1976L70.5988 41.4597H97V55.5403H70.5988L61.7984 52.8024L60.8206 55.1492L70.5988 60.625L87.8085 77.8347L77.8347 87.8085L60.625 70.5988L55.1492 60.8206L52.8024 61.7984L55.5403 70.5988V97H41.4597V70.5988L44.1976 61.7984L41.8508 60.8206L36.375 70.5988L19.1653 87.8085L9.19153 77.8347L26.4012 60.625L36.1794 55.1492L35.2016 52.8024L26.4012 55.5403H0V41.4597H26.4012L35.2016 44.1976L36.1794 41.8508L26.4012 36.375L9.19153 19.1653L19.1653 9.19155L36.375 26.4012L41.8508 36.1794L44.1976 35.2016L41.4597 26.4012V0H55.5403V26.4012L52.8024 35.2016L55.1492 36.1794L60.625 26.4012L77.8347 9.19155L87.8085 19.1653Z"
                fill="white"
              />
            </Box>
          </Flex>
        </InfiniteLooper>
        <DropList drops={dropList} />
      </Box>
    </Wrapper>
  );
}

export const getServerSideProps = getSsrPropsWrapper(async (_) => {
  const res = await defaultRedaxios.get(
    `https://dissrup.com/static_data/drop_index.json`
  );

  const resList = await defaultRedaxios.get(
    `https://dissrup.com/static_data/dropListData.json`
  );

  const { data } = res;

  const dropListData = resList.data;

  return {
    props: { ...data, dropListData },
  };
});
