import React, { useMemo } from "react";
import MuxVideo from "root/components/MuxVideo";
import { DropListType } from "root/hooks/useDroplistData";
import Image from "next/image";
import { Box } from "root/components/design-system";
import styled from "styled-components";

const Wrapper = styled(Box)`
  position: relative;

  video {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export default function DropMedia({ drop }: { drop: DropListType }) {
  const mediaType = useMemo(() => {
    if (drop?.slideConfig?.video?.asset?.playbackId) return "video";

    if (drop?.slideConfig?.bgImage?.length > 0) return "image";

    return "";
  }, [drop]);

  return (
    <Wrapper position="relative" height="100%">
      {mediaType === "video" && (
        <MuxVideo
          playbackId={drop.slideConfig.video.asset.playbackId}
          autoPlay
          playsInline
          muted
          loop
          hlsConfig={{ startLevel: 4 }}
          style={{ width: "100%" }}
        />
      )}
      {mediaType === "image" && (
        <Box width={1}>
          <Image
            src={drop.slideConfig.bgImage[0].asset.url}
            alt={`Picture of ${drop.name}`}
            width={drop.slideConfig.bgImage[0].asset.metadata.dimensions.width}
            height={
              drop.slideConfig.bgImage[0].asset.metadata.dimensions.height
            }
            placeholder="blur"
            blurDataURL={drop.slideConfig.bgImage[0].asset.metadata.lqip}
          />
        </Box>
      )}
      {mediaType === "" && <Box width={1} height="90vh" bg="gray-20" />}
    </Wrapper>
  );
}
