import React from "react";
import { DropListType } from "root/hooks/useDroplistData";
import useServerState from "root/hooks/useServerState";
import Link from "next/link";
import format from "date-fns/format";
import { AccordionButton, AccordionPanel } from "@reach/accordion";
import "@reach/accordion/styles.css";
import {
  Box,
  Flex,
  Hidden,
  Typography,
  Visible,
} from "root/components/design-system";
import { up } from "styled-breakpoints";
import { Routes } from "types/routeTypes";
import styled from "styled-components";
import DropMedia from "./DropMedia";

const ToggleIcon = styled(Flex)`
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;

  div {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 1px;
    background: #fff;
  }
`;

const StyledAccordionButton = styled(AccordionButton)`
  padding: 0;
  width: 100%;
  color: #fff;
  border: 0;
  background: none;

  &[data-state="open"] {
    ${ToggleIcon} {
      div:first-of-type {
        transform: rotate(90deg);
      }
    }
  }

  :focus {
    outline: none;
  }
`;

const StyledAccordionPanel = styled(AccordionPanel)`
  position: relative;
  max-height: 1000px;
  overflow: hidden;
  transition: all 0.3s ease;

  &[hidden] {
    max-height: 0px;
    display: block;
  }
`;

const PanelInfoItem = styled(Flex)`
  flex-wrap: wrap;
  align-items: baseline;
  color: #fff;
`;

const CategoryTag = styled(Typography)`
  align-self: center;
  margin-left: 0.5rem;
  padding: 4px 8px;
  font-size: 0.625rem;
  color: #fff;
  border: 0.25px solid #fff;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${up("lg")} {
    font-size: 1rem;
  }
`;

const ListItem = styled(Flex)`
  p {
    opacity: 1;
    transition: opacity 0.4s ease;
  }

  ::after,
  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 0;
    height: 1px;
    background: #fff;
    transition: width 0.6s ease-out;
  }

  ::before {
    top: 0;
    bottom: unset;
  }

  :hover {
    p {
      opacity: 0.7;
    }

    ::after,
    ::before {
      width: 100%;
    }
  }
`;

const DropNameTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function DropItem({ drop }: { drop: DropListType }) {
  const { contractsInfo } = useServerState();

  const provenanceText = () => {
    if (!drop.blockchainAssets.length) {
      return "No longer listed";
    }

    if (
      contractsInfo.assetContract.toLowerCase() ===
      drop.blockchainAssets?.[0]?.contractAddress.toLowerCase()
    ) {
      return "Dissrup Contract";
    }

    return "Custom Manifold Contract";
  };

  const categories = () => {
    const tags = [];

    if (drop.assetTypes.includes("ARTWORK")) {
      tags.push("1 of 1 Artwork");
    }

    if (
      drop.assetTypes.includes("DIGITAL_COLLECTIBLE") ||
      drop.assetTypes.includes("PHYGITAL_COLLECTIBLE")
    ) {
      tags.push("Collectibles");
    }

    if (drop.assetTypes.includes("PHYGITAL_COLLECTIBLE")) {
      tags.push("Phygital");
    }

    return tags;
  };

  return (
    <>
      <StyledAccordionButton>
        <ListItem
          position="relative"
          flexWrap="wrap"
          width="100%"
          py={{ _: "1.25rem", lg: "1rem" }}
          borderTop="1px solid rgba(255, 255, 255, 0.3)"
        >
          <Flex alignItems="baseline" width={1}>
            <Box
              as="p"
              width={{ _: "3rem", lg: "auto" }}
              fontFamily="saintColombe"
              fontSize={{ _: "1.31rem", lg: "2.62rem" }}
              letterSpacing="-0.02em"
              mr={{ _: "0.25rem", lg: "1.5rem" }}
            >
              {drop.dropNumber.toString().padStart(3, "0")}
            </Box>
            <Link href={`${Routes.DROP}/${drop.slug.current}`} passHref>
              <Box width={{ _: "200px", lg: "800px" }} as="a" textAlign="left">
                <DropNameTypography
                  fontFamily="apocRevelations"
                  fontSize={{ _: "1.5rem", lg: "3.75rem" }}
                  color="#fff"
                  mr={{ lg: "1rem" }}
                >
                  {drop.name}
                </DropNameTypography>
              </Box>
            </Link>
            <Hidden sm md>
              <Box
                as="p"
                width={{ _: "100%", md: "auto" }}
                fontSize={{ _: "1rem", lg: "2rem" }}
                fontWeight="100"
              >
                by <i>{drop.author}</i>
              </Box>
            </Hidden>

            <Flex
              posiiton="relative"
              ml="auto"
              alignItems="baseline"
              justifyContent="flex-end"
              flex="1 0 auto"
            >
              {/* {isNew && ( */}
              {/*  <NewBadgeWrapper */}
              {/*    position={{ _: "absolute", md: "static" }} */}
              {/*    bottom="1.1rem" */}
              {/*    right="0" */}
              {/*    alignSelf="center" */}
              {/*    mr={{ lg: "4rem" }} */}
              {/*    py={{ _: "4px", lg: "6px" }} */}
              {/*    px={{ _: "6px", lg: "24px" }} */}
              {/*    borderRadius="11px" */}
              {/*  > */}
              {/*    <Typography */}
              {/*      fontSize={{ _: "8px", lg: "14px" }} */}
              {/*      fontWeight="medium" */}
              {/*    > */}
              {/*      <i>NEW!</i> */}
              {/*    </Typography> */}
              {/*  </NewBadgeWrapper> */}
              {/* )} */}

              {/* <Typography */}
              {/*  fontFamily="saintColombe" */}
              {/*  fontSize={{ _: "0.875rem", lg: "2.625rem" }} */}
              {/*  letterSpacing="-0.02em" */}
              {/*  mr={{ _: "0.5rem", lg: "2rem" }} */}
              {/* > */}
              {/*  {availabilityText()} */}
              {/* </Typography> */}
              <ToggleIcon
                width={{ _: "1rem", lg: "2rem" }}
                height={{ _: "1rem", lg: "2rem" }}
              >
                <Box
                  width="1px"
                  height={{ _: "0.5rem", lg: "1rem" }}
                  transition="all 0.3s ease"
                />
                <Box
                  transform="rotate(90deg)"
                  m="auto"
                  height={{ _: "0.5rem", lg: "1rem" }}
                />
              </ToggleIcon>
            </Flex>
          </Flex>
          <Visible sm md>
            <Box
              ml="3.25rem"
              as="p"
              fontSize={{ _: "1rem", lg: "2rem" }}
              fontWeight="100"
            >
              by <i>{drop.author}</i>
            </Box>
          </Visible>
        </ListItem>
      </StyledAccordionButton>
      <StyledAccordionPanel>
        <Link href={`${Routes.DROP}/${drop.slug.current}`} passHref>
          <a>
            <DropMedia drop={drop} />
          </a>
        </Link>
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          pb={{ _: "2rem", lg: "0" }}
        >
          <PanelInfoItem
            py={{ _: "1rem", lg: "2rem" }}
            width={{ _: "50%", lg: "auto" }}
          >
            <Typography
              fontFamily="apocRevelations"
              fontSize={{ _: "0.75rem", lg: "1.375rem" }}
              mr={{ _: "4px", lg: "12px" }}
            >
              Release Date:
            </Typography>
            <Typography
              fontSize={{ _: "0.75rem", lg: "1.275rem" }}
              fontWeight="light"
            >
              {format(new Date(drop.releaseDate), "dd / MM / yy")}
            </Typography>
          </PanelInfoItem>
          <PanelInfoItem
            py={{ _: "1rem", lg: "2rem" }}
            width={{ _: "50%", lg: "auto" }}
          >
            <Typography
              fontFamily="apocRevelations"
              fontSize={{ _: "0.75rem", lg: "1.375rem" }}
              mr={{ _: "4px", lg: "12px" }}
            >
              Provenance:
            </Typography>
            <Typography
              fontSize={{ _: "0.75rem", lg: "1.275rem" }}
              fontWeight="light"
              mr="4px"
            >
              {provenanceText()}
            </Typography>
            &nbsp;-&nbsp;
            {drop.blockchainAssets?.[0] && (
              <Box
                as="a"
                href={`${contractsInfo.etherscanUrl}/address/${drop.blockchainAssets[0].contractAddress}`}
                target="_blank"
                rel="noreferrer"
                width={{ _: "100%", lg: "auto" }}
                fontWeight="light"
                data-cursor-pointer
                data-underliner
              >
                <Typography
                  fontSize={{ _: "0.75rem", lg: "1.275rem" }}
                  fontWeight="inherit"
                  color="#fff"
                  fontStyle="italic"
                >
                  view on Etherscan
                </Typography>
              </Box>
            )}
          </PanelInfoItem>
          {drop.blockchainAssets.length > 0 && (
            <PanelInfoItem
              py={{ _: "0.5rem", lg: "2rem" }}
              width={{ _: "100%", lg: "auto" }}
            >
              <Typography
                fontFamily="apocRevelations"
                fontSize={{ _: "0.75rem", lg: "1.375rem" }}
                mr={{ _: "4px", lg: "12px" }}
                css="align-self: center"
              >
                Categories:
              </Typography>
              {categories().map((item) => (
                <CategoryTag key={item}>{item}</CategoryTag>
              ))}
            </PanelInfoItem>
          )}
        </Flex>
      </StyledAccordionPanel>
    </>
  );
}
